import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import './Header.scss';
import banner from '../images/banner.png';
import cv from '../pdf/eiselmayer-cv.pdf';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faGithub} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';

export const contactInfo = <>
  <p>
    <span
      className={'icon'}>
      <a href={'mailto:alexander@eiselmayer.com'}>
        <FontAwesomeIcon icon={faEnvelope}/>
      </a>
    </span>
    <span className={'link'}>
      <a href={'mailto:alexander@eiselmayer.com'}>
            alexander@eiselmayer.com
      </a>
    </span>
  </p>

  <p>
    <span
      className={'icon'}>
      <a href={cv} target={'_blank'}>
        <FontAwesomeIcon icon={faFilePdf}/>
      </a>
    </span>
    <span className={'link'}>
      <a href={cv} target={'_blank'}>
            Curriculum Vitae
      </a>
    </span>
  </p>

  <p>
    <span
      className={'icon'}>
      <a href={'https://github.com/eiselmayer'} target={'_blank'}>
        <FontAwesomeIcon icon={faGithub}/>
      </a>
    </span>
    <span className={'link'}>
      <a href={'https://github.com/eiselmayer'} target={'_blank'}>
            github.com/eiselmayer
      </a>
    </span>
  </p>

  <p>
    <span
      className={'icon'}>
      <a href={'https://www.linkedin.com/in/eiselmayer/'} target={'_blank'}>
        <FontAwesomeIcon icon={faLinkedin}/>
      </a>
    </span>
    <span className={'link'}>
      <a href={'https://www.linkedin.com/in/eiselmayer/'} target={'_blank'}>
            linkedin.com/in/eiselmayer/
      </a>
    </span>
  </p>
</>;

class Header extends PureComponent {
  render() {
    return <div className={'header'}>
      <img src={banner} alt={'Alexander Eiselmayer'}/>
      <h1>Alexander<br/>Eiselmayer</h1>
      <div className={'overlay'}>
        {contactInfo}
      </div>
    </div>;
  }
}

Header.propTypes = {
  children: PropTypes.any,
};

Header.defaultProps = {
  children: '',
};

export default Header;


