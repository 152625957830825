import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import './Education.scss';

class Education extends PureComponent {
  render() {
    return <div className={'education'}>
      <div className={'education__text'}>
        <h1>Education,</h1>
      </div>
      <div className={'education__item'}>
        <div className={'education__item__time'}>
          <p>Since 08/2018</p>
        </div>
        <div className={'education__item__text'}>
          <h2>Ph.D. in Human-Computer-Interaction</h2>
          <h3>at University of Zurich, Switzerland</h3>
          <p>
            Supervisor: Chat Wacharamanotham<br/>
            Thesis adviser: Wendy Mackay
          </p>
          <p>
            Focus: Experimental Design, Trade-Off Exploration, Human-Computer
            Partnership
          </p>
        </div>
      </div>
      <div className={'education__item'}>
        <div className={'education__item__time'}>
          <p>2016 &mdash; 2018</p>
        </div>
        <div className={'education__item__text'}>
          <h2>Master of Science in Information Systems</h2>
          <h3>at University of Zurich, Switzerland</h3>
          <p>
            GPA: 5.2, magna cum laude, Grade range: 6.0 (best) – 4.0
          </p>
          <p>
            Master Thesis: Touchstone 2: A Visual Programming Interface for
            Designing and
            Comparing Experiments
          </p>
        </div>
      </div>

      <div className={'education__item'}>
        <div className={'education__item__time'}>
          <p>2012 &mdash; 2016</p>
        </div>
        <div className={'education__item__text'}>
          <h2>Bachelor of Science in Information Systems</h2>
          <h3>at Technical University Munich, Germany</h3>
          <p>
            Bachelor Thesis: An Analysis of Iterative Auction Formats based on
            The Canadian 700 Mhz Spectrum Auction (2014)
          </p>
        </div>
      </div>
    </div>;
  }
}

Education.propTypes = {
  children: PropTypes.any,
};

Education.defaultProps = {
  children: '',
};

export default Education;


