import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import './News.scss';


class News extends PureComponent {
  render() {
    return <div className={'news'}>
      <div className="news__content">
        <h1>News,</h1>
        <div className={'news-list'}>
          <div className={'left'}>
            <h2>Talk at CHI &apos;19</h2>
            <p>I will be presenting Touchstone2 at this year&apos;s CHI
              conference in
              Glasgow, UK. If you would like to attend the <a
              href="http://chi2019.acm.org/web-program.php?sessionId=4d438568966bee442b1aecdd25b0c159de294e4fbf3096e7238797a20b4cad68&publicationId=pn2772"
              target="_blank" rel="noopener noreferrer">talk</a> make
              sure to
              join the last paper session on Monday, 06. May in Hall 1.</p>
            <p className={'date'}>May 2019</p>
          </div>
          <div className={'right'}>
            <h2>CHI2020 Web Chair</h2>
            <p>
              I am excited to be part of the <a href="https://chi2020.acm.org"
                target="_blank"
                rel="noopener noreferrer">CHI2020</a> Web
              Chair. Wanyu Liu and I
              are responsible to implement and maintain the official website.
            </p>
            <p className={'date'}>Sept 2018</p>
          </div>
        </div>

      </div>
    </div>;
  }
}

News.propTypes = {
  children: PropTypes.any,
};

News.defaultProps = {
  children: '',
};

export default News;


