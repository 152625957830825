import React, {Component} from 'react';
import './App.scss';
import AboutMe from './modules/about-me/AboutMe';
import Header from './components/Header';
import Education from './components/Education';
import Publications from './components/Publications';
import News from './components/News';
import Work from './components/Work';
import Footer from './components/Footer';

class App extends Component {
  render() {
    return (
      <div>
        <Header/>
        <AboutMe/>
        <Publications/>
        <News/>
        <Education/>
        <Work/>
        <Footer/>
      </div>
    );
  }
}

export default App;
