import React, {PureComponent} from 'react';
import './Footer.scss';
import {contactInfo} from './Header';


class Footer extends PureComponent {
  render() {
    return <div className={'footer'}>
      <div className={'footer__text'}>
        <h1>Contact Me,</h1>
        <div className={'content'}>
          <div className={'contact-info'}>
            {contactInfo}
          </div>
        </div>

      </div>
      <div className={'line'}/>
      <div className={'copyright'}>
        <p>&copy; Alexander Eiselmayer</p>
      </div>
    </div>;
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
