import React, {PureComponent} from 'react';
import './AboutMe.scss';


class AboutMe extends PureComponent {
  render() {
    return <div className={'about-me'}>
      <div className={'about-me__text'}>
        <h1>Hi,</h1>
        <p>
          I am a Ph.D. student in Human-Computer-Interaction at the University
          of Zurich.
          Together with my supervisor Prof. Dr. Chat
          Wacharamanotham and in close collaboration with RD Wendy Mackay, I am
          looking at researchers who design and conduct controlled experiment.
          I am very interested in how researchers make decisions in a complex
          environment, where not optimal solution exists.
        </p>

        <p>
          With <i>Touchstone2</i>, we created a web application that facilitates
          the comparison of different experimental designs. The researcher can
          weight different trade-offs to find a design which is suitable for
          the given research questions.
        </p>

        <p>
          Scuba diving and ballroom dancing are my two favourite hobbies. The
          former opens up the truly magnificent underwater world for
          exploration. The latter fills my heart with joy and satisfaction.
        </p>
      </div>
    </div>;
  }
}

AboutMe.propTypes = {};

AboutMe.defaultProps = {};

export default AboutMe;
