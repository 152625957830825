import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import './Work.scss';

class Work extends PureComponent {
  render() {
    return <div className={'work'}>
      <div className={'work__text'}>
        <h1>Experience,</h1>
      </div>

      <div className={'work__item'}>
        <div className={'work__item__time'}>
          <p>Since 08/2018</p>
        </div>
        <div className={'work__item__text'}>
          <h2>Ph.D. in Human-Computer-Interaction</h2>
          <h3>at University of Zurich, Switzerland</h3>
        </div>
      </div>

      <div className={'work__item'}>
        <div className={'work__item__time'}>
          <p>04/2021 &mdash; 06/2021</p>
        </div>
        <div className={'work__item__text'}>
          <h2>User Experience Designer</h2>
          <h3>at Netlight Consulting, Munich, Germany</h3>
        </div>
      </div>

      <div className={'work__item'}>
        <div className={'work__item__time'}>
          <p>Since 01/2016</p>
        </div>
        <div className={'work__item__text'}>
          <h2>Head of IT & Operations</h2>
          <h3>at PRIO Partners GmbH, Zurich, Switzerland</h3>
        </div>
      </div>

      <div className={'work__item'}>
        <div className={'work__item__time'}>
          <p>2015 &mdash; 2016</p>
        </div>
        <div className={'work__item__text'}>
          <h2>Web Developer</h2>
          <h3>at JKweb GmbH, Zurich, Switzerland</h3>
        </div>
      </div>
    </div>;
  }
}

Work.propTypes = {
  children: PropTypes.any,
};

Work.defaultProps = {
  children: '',
};

export default Work;


