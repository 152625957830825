import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';
import './Publications.scss';
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';
import {faLaptopCode} from '@fortawesome/free-solid-svg-icons';
import {faTrophy} from '@fortawesome/free-solid-svg-icons';
import {faGithub} from '@fortawesome/free-brands-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import touchstone2 from '../modules/publications/touchstone2.svg';
import paper
  from '../pdf/Eiselmayer et al. (2019) - Touchstone2 An Interactive Environment for Exploring Trade-offs in HCI Experiment Design.pdf';
import dc from '../pdf/Eiselmayer (2020) - Supporting the Design and Analysis of HCI Experiments.pdf'


class Publications extends PureComponent {
  render() {
    return <div className={'publications'}>
      <div className="publications__content">
        <h1>Publications,</h1>

        <div className={'publication'}>
          {/*<div className="publication__image">*/}
          {/*  <img src={touchstone2} alt="Touchstone2"/>*/}
          {/*</div>*/}
          <div className="publication__text">
            <p>
              Conference paper<br/>
              to appear at VIS 2020
            </p>
            <h2>Argus: Interactive <i>a priori</i> Power Analysis</h2>

            <div className={'publication__text__authors'}>
              <p>Xiaoyi Wang, &nbsp;</p>
              <p><b>Alexander Eiselmayer</b>,&nbsp;</p>
              <p>Wendy E. Mackay, &nbsp;</p>
              <p>Kasper Hornbæk, &nbsp;</p>
              <p>Chat Wacharamanotham</p>
            </div>

            <div className="button-container">
              <div>
                <Button href="https://arxiv.org/abs/2009.07564" target="_blank">
                  <FontAwesomeIcon icon={faFilePdf}/>&nbsp;View PDF
                </Button>
              </div>
              <div>
                <Button href="https://argus.shinyapps.io/project-argus/" target="_blank">
                  <FontAwesomeIcon icon={faLaptopCode}/>&nbsp;Demo
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={'publication'}>
          {/*<div className="publication__image">*/}
          {/*  <img src={touchstone2} alt="Touchstone2"/>*/}
          {/*</div>*/}
          <div className="publication__text">
            <p>
              Doctoral Consortium<br/>
              In Proceedings of CHI 2020, Hawai`i, US
            </p>
            <h2>Supporting the Design and Analysis of HCI Experiments</h2>

            <div className={'publication__text__authors'}>
              <p>Alexander Eiselmayer</p>
            </div>

            <div className="button-container">
              <div>
                <Button href={dc}>
                  <FontAwesomeIcon icon={faFilePdf}/>&nbsp;View PDF
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={'publication'}>
          {/*<div className="publication__image">*/}
          {/*  <img src={touchstone2} alt="Touchstone2"/>*/}
          {/*</div>*/}
          <div className="publication__text">
            <p>
              Conference paper<br/>
              In Proceedings of CHI 2019, Glasgow, UK
            </p>
            <h2><i>Touchstone2</i> : An Interactive Environment for Exploring
              Trade-offs in HCI Experiment Design</h2>

            <div className={'publication__text__authors'}>
              <p><b>Alexander Eiselmayer</b>,&nbsp;</p>
              <p>Chat Wacharamanotham, &nbsp;</p>
              <p>Michel Beaudouin-Lafon, &nbsp;</p>
              <p>Wendy E. Mackay</p>
            </div>

            <p>
              <FontAwesomeIcon icon={faTrophy}
                className={'publication__text__award'}/>
              &nbsp; Best Paper Award (Top 1%)
            </p>
            <div className="button-container">
              <div>
                <Button href={paper}>
                  <FontAwesomeIcon icon={faFilePdf}/>&nbsp;View PDF
                </Button>
              </div>
               <div>
                <Button href="https://www.youtube.com/watch?v=g9ZBPBTxT1k" target="_blank">
                  <FontAwesomeIcon icon={faYoutube}/>&nbsp;Preview
                </Button>
               </div>
              {/* <div>*/}
              {/*  <Button href="#text-buttons">*/}
              {/*    <FontAwesomeIcon icon={faVideo}/>&nbsp;Video*/}
              {/*  </Button>*/}
              {/* </div>*/}
              <div>
                <Button href="https://github.com/ZPAC-UZH/Touchstone2" target="_blank">
                  <FontAwesomeIcon icon={faGithub}/>&nbsp;Code
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>;
  }
}

Publications.propTypes = {
  children: PropTypes.any,
};

Publications.defaultProps = {
  children: '',
};

export default Publications;


